import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import { useGetMainQuery } from "services/clientApi";
import { useSelector } from "react-redux";
import "./mobileSide.scss";

const MobileSide = ({ isAdmin, openSideBar }) => {
  const navigate = useNavigate();
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const [openModalPhone, setOpenModalPhone] = useState(false);
  const [isMobile, setIsMobile] = useState("");
  const screenWidth = useSelector((state) => state?.common?.screenWidth);
  const {
    data: main,
    isLoading: loading,
    isError: error,
  } = isAdmin ? "" : useGetMainQuery();
  const triggerModal = (el) => {
    setOpenModalEmail(!openModalEmail);
    setContactEmail(el);
  };
  const triggerModalPhone = (el) => {
    setOpenModalPhone(!openModalPhone);
  };

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const sideBarElements = [
    {
      name: "Home",
      className: "icon-dashboard",
      path: "/",
      icon: "fa-solid fa-house-user",
    },

    {
      name: "Transactions",
      className: "icon-transactions-v2",
      path: "/transactions",
      icon: "fa-solid fa-right-left",
    },
    {
      name: "Exchange",
      className: "fa-solid fa-arrows-rotate",
      path: "/exchange",
      icon: "fa-solid fa-arrows-rotate",
    },

    {
      name: "Investments",
      className: "icon-projects",
      path: "/investments",
      icon: "fa-solid fa-chart-line",
    },
    {
      name: "Profile",
      className: "icon-projects",
      path: "/profile",
      icon: "fa-regular fa-user",
    },
  ];
  const sideBarElementsAdmin = [
    {
      name: "Home",
      className: "icon-dashboard",
      path: "/admin/home",
      icon: "fa-solid fa-house-user",
    },

    {
      name: "Transactions",
      className: "icon-transactions-v2",
      path: "/admin/transactions",
      icon: "fa-solid fa-money-bill-transfer",
    },

    {
      name: "Profile",
      className: "icon-projects",
      path: "/admin/profile",
      icon: "fa-regular fa-user",
    },
    {
      name: "Settings",
      className: "icon-projects",
      path: "/settings",
      icon: "fa-solid fa-gears",
    },
    {
      name: "Calendar",
      className: "icon-projects",
      path: "/calendar",
      icon: "fa-regular fa-calendar-days",
    },
  ];
  const logOut = () => {
    if (isAdmin) {
      localStorage.clear();
      window.location.href = "https://techno-robotix.tech";
    } else {
      localStorage.removeItem("isClient");
      localStorage.removeItem("auth_client");
      navigate("/login");
    }
  };

  const pathController = window.location.pathname;

  return (
    <div className="shadow mobile-side bg-white position-relative">
      <div className="border-bottom text-end p-3">
        <span className="w-100 " type="button" onClick={() => openSideBar()}>
          <i className="fa-solid fa-xmark fs-1" />
        </span>
      </div>
      <div className="text-center mt-4">
        <img src={Logo} style={{ width: "100%" }} />
      </div>
      <div className={` ${isMobile ? "mt-1" : "mt-5"}`}>
        {(isAdmin ? sideBarElementsAdmin : sideBarElements).map(
          (item, index) => (
            <div className="mb-3">
              <div
                key={index}
                role="button"
                className={` ${
                  pathController === item.path
                    ? "side-elements-selected"
                    : "side-elements"
                }`}
              >
                <div className="text-center position-relative ">
                  <div
                    className={`${
                      pathController === item.path ? "selected-rounded " : ""
                    }`}
                  >
                    <li
                      className={`list-group-item fw-bold list-group-item-action text-center rounded  border-0 ${
                        pathController === item.path ? "" : ""
                      }`}
                    >
                      <div
                        className={`  ${
                          pathController === item.path
                            ? "background-active"
                            : "background-non-active"
                        }`}
                      >
                        <div
                          onClick={() => navigate(item.path)}
                          className="text-center "
                          style={{ fontSize: "22px" }}
                        >
                          <div>
                            <div className="w-100 text-center">
                              <i
                                style={{
                                  color: `${
                                    pathController === item.path
                                      ? "white"
                                      : "rgb(98, 109, 126)"
                                  }`,

                                  margin: "auto",
                                  fontSize: "15px",
                                }}
                                className={` ${item.icon}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>{" "}
                </div>
                <div className="w-100 text-center ">
                  <h6 style={{ fontSize: "13px" }} className="grey">
                    {item.name}
                  </h6>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <div className="mt-5 text-center w-100">
        {isAdmin ? (
          ""
        ) : (
          <button
            onClick={() => navigate("/transactions")}
            className="btn btn-primary  btn-sm shadow-lg custom-rounded transfer-header"
          >
            Transfer
          </button>
        )}
      </div>
      <div className="mobile-side-footer position-relative">
        <div className="w-100">
          {openModalEmail ? (
            <div className="rounded email-popup  bg-white border text-center ">
              <h5 className="mt-1 text-grey">Email</h5>
              <h6 className="m-3">{main?.company?.email}</h6>
              <div className="w-100 text-end p-3">
                <button onClick={triggerModal} className="btn btn-primary">
                  Close
                </button>
              </div>
            </div>
          ) : (
            ""
          )}

          <div
            // onClick={() => triggerModal("email")}
            type="button"
            className="email-circle w-50 m-auto shadow"
            data-container="body"
          >
            <i
              // onClick={triggerModal}
              className="fa-regular text-primary fa-envelope fs-3"
            />
          </div>
          {/* <p className="">info@unibank.com</p> */}
        </div>
        <div className="w-100 mt-4">
          {openModalPhone ? (
            <div className="rounded email-popup  bg-white border text-center ">
              <h5 className="mt-1 text-grey">Custom Service</h5>
              <h6 className="m-3">+44 1315 070 203</h6>
              <div className="w-100 text-end p-3">
                <button onClick={triggerModalPhone} className="btn btn-primary">
                  Close
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            onClick={main?.company?.id === 72 ? "" : triggerModalPhone}
            type="button"
            className="email-circle w-50 m-auto shadow"
          >
            <i className="fa-solid fa-phone fs-3 text-primary" />
          </div>
        </div>
      </div>
      <div
        type="button"
        className=" text-center mb-3  w-100 mt-1 position-absolute bottom-0 end-0"
        onClick={logOut}
      >
        {" "}
        <p className="light-grey mt-1 fw-bold">Log Out</p>
        <i
          style={{ fontSize: "30px" }}
          className="text-primary fa-solid fa-power-off"
        />
      </div>
    </div>
  );
};

export default MobileSide;
