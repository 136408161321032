import React, { useState } from "react";
import { toast } from "react-toastify";
import "./table.scss";
import moment from "moment";
import Details from "./Details";
import {
  useLazyGetClientTransactionsDetailsQuery,
  useGetMainQuery,
} from "services/clientApi";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import formatNumber from "../../../helpers/NumberFormat";

import MyDoc from "shared/PdfDoc/Pdf.jsx";

const Table = ({ transactions }) => {
  const [getDetails, { data: details }] =
    useLazyGetClientTransactionsDetailsQuery();
  const [openModal, setOpenModal] = useState(false);
  const { data: main, isLoading: loading, isError: error } = useGetMainQuery();

  const isLocalHost = !!(
    location.hostname === "localhost" || location.hostname === "127.0.0.1"
  );

  const baseUrl = isLocalHost
    ? "http://bankio.stage/backend"
    : window.location.protocol + "/backend";

  const triggerModal = () => {
    setOpenModal(!openModal);
  };

  const getDetailsFunc = (id) => {
    triggerModal();
    getDetails(id);
  };

  const handleClick = (filePath) => {
    if (!filePath) {
      toast.error("No receipt has been generated yet");
      return;
    }
    
    window.open(baseUrl + filePath, "_blank");
  };

  return (
    <div className="table-wrapper">
      <Details
        main={main}
        details={details}
        openModal={openModal}
        triggerModal={triggerModal}
      />
      <div className="p-3"></div>
      <div className="table-wrapper-h">
        <table className="table m-auto ">
          <thead className="">
            <tr className="bg-primary text-white">
              <th scope="col" className=" fw-normal text-center text-white">
                ID
              </th>
              <th scope="col" className=" fw-normal text-center text-white">
                Date
              </th>
              <th scope="col" className=" fw-normal text-center text-white">
                Beneficiary
              </th>

              <th scope="col" className=" fw-normal text-center text-white">
                Amount
              </th>
              <th scope="col" className=" fw-normal text-center text-white">
                Type
              </th>
              <th scope="col" className=" fw-normal text-center text-white">
                Status
              </th>
              <th scope="col" className=" fw-normal text-center text-white">
                Invoice
              </th>
              <th scope="col" className=" fw-normal text-center text-white">
                Receipt
              </th>
              <th
                scope="col"
                className=" fw-normal text-center text-white"
              ></th>
            </tr>
          </thead>
          <tbody>
            {transactions?.map((el, index) => (
              <tr key={index} style={{ height: "50px" }}>
                <th className="text-primary text-center  " scope="row">
                  {el?.reference_code}
                </th>
                <td className="fw-bold text-center">
                  {" "}
                  {moment(el.created_at).format("DD/MM/YYYY")}
                </td>
                <td className="fw-bold text-center"> {el.beneficary_name}</td>
                <td className="fw-bold text-center">
                  {formatNumber(el?.amount)} €
                </td>
                <td
                  className={` bg-light text-center  ${el.type === "in" ? "text-success" : "text-primary"
                    }`}
                >
                  {el?.type === "in" ? "Incoming" : "Outgoing"}
                </td>
                <td
                  style={{ color: el?.status?.color }}
                  className={` text-center }`}
                >
                  {el?.status?.name}
                </td>

                <td className="">
                  <div
                    className="d-flex justify-content-center gap-2"
                    style={{ cursor: "pointer" }}
                  >
                    {/* <i
                      className="info fa-solid fa-circle-info mt-1"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Details"
                      onClick={() => getDetailsFunc(el.id)}
                    ></i> */}
                    <PDFDownloadLink
                      document={
                        <MyDoc
                          details={el}
                          account={main?.user?.detail?.account_number}
                        />
                      }
                      fileName={"Unibank"}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading..."
                        ) : (
                          <i
                            className="info fa-solid fa-circle-info mt-1 mr-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Download Your Invoice"
                          ></i>
                        )
                      }
                    </PDFDownloadLink>
                  </div>
                </td>

                <td className="">
                  <div
                    className="d-flex justify-content-center gap-2"
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="download fa-solid fa-download mt-1 ml-2"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Download Your Receipt"
                      onClick={() => handleClick(el.receipt_file_path)}
                    ></i>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
