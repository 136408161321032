import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

const customBackOff = async (attempt = 0, maxRetries = 0) => {
  const attempts = Math.min(attempt, maxRetries);

  await new Promise((resolve) => {
    setTimeout(resolve, attempts * 1000);
  });
};

const baseQuery = (baseQueryOptions) => async (args, api, extraOptions) => {
  const result = await fetchBaseQuery(baseQueryOptions)(
    args,
    api,
    extraOptions
  );

  if (result.error) {
    toast.error(result.error.data.error, {
      toastId: result.error.data.error,
    });
  }
  return result;
};

const isLocalHost = !!(
  location.hostname === "localhost" || location.hostname === "127.0.0.1"
);

export const mainApi = createApi({
  reducerPath: "main",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: "https:",
  // }),
  baseQuery: baseQuery({
    baseUrl: isLocalHost
      ? "http://bankio.stage/backend"
      : window.location.protocol + "/backend",
    backoff: customBackOff,
    maxRetries: 0,

    prepareHeaders: (headers) => {
      const url = new URL(window.location.href);
      const urlToken = url.searchParams.get("access_token");
      const auth = localStorage.getItem("auth") || urlToken;
      const client_auth = localStorage.getItem("auth_client") || urlToken;
      if (auth) {
        headers.set("Authorization", `Bearer ${auth}`);
      } else {
        if (client_auth) {
          headers.set("Authorization", `Bearer ${client_auth}`);
        }
      }
      return headers;
    },
  }),
  tagTypes: [
    "getMain",
    "MainAdmin",
    "getClients",
    "getTransaction",
    "getNotification",
    "Status",
    "getSchedule",
  ],
  endpoints: (builder) => ({
    // GETS

    // getAdminMain: builder.query({
    //   query: () => ({
    //     url: "api/main",
    //     method: "GET",
    //   }),
    //   providesTags: ["Main"],
    // }),

    getAdminMain: builder.query({
      query: () => ({
        url: "api/main",
        method: "GET",
      }),
      providesTags: ["getMain"],
    }),

    getAdminNotification: builder.query({
      query: () => ({
        url: "api/notifications",
        method: "GET",
      }),
      providesTags: ["getNotification"],
    }),

    getALLClientsList: builder.query({
      query: (args) => {
        return {
          url: "/api/clients",
          method: "GET",
        };
      },
    }),

    getClientsList: builder.query({
      query: (args) => {
        const { start, take, search } = args;
        return {
          url: "/api/clients",
          method: "GET",
          params: {
            start,
            take,
            search,
          },
        };
      },
      providesTags: ["getClients"],
    }),

    getSingleClient: builder.query({
      query: (id) => ({
        url: `api/client/${id}/details`,
        method: "GET",
      }),
    }),
    getSingleAdminTransactions: builder.query({
      query: (id) => {
        return {
          url: `api/client/transactions/${id}`,
          method: "GET",
        };
      },
    }),

    getTransactionsAdmin: builder.query({
      query: (id) => ({
        url: `api/client/${id}/transactions`,
        method: "GET",
      }),
      providesTags: ["getTransaction"],
    }),

    getScheduleAdmin: builder.query({
      query: ({ search }) => ({
        url: `api/clients/schedules`,
        method: "GET",
        params: {
          search,
        },
      }),
      providesTags: ["getSchedule"],
    }),

    // getScheduleAdmin: builder.query({
    //   query: (args) => {
    //     const { search } = args;
    //     return {
    //       url: "api/clients/schedules",
    //       method: "GET",
    //       params: {
    //         search,
    //       },
    //     };
    //   },
    //   providesTags: ["getClients"],
    // }),

    // POST

    //Admin
    createClient: builder.mutation({
      query: (data) => ({
        url: `api/register`,
        method: "POST",
        body: data,
      }),
    }),

    //Client
    loginAsClient: builder.mutation({
      query: (id) => ({
        url: `api/client/${id}/login`,
        method: "POST",
      }),
    }),

    createTransaction: builder.mutation({
      query: ({ id, data }) => ({
        url: `api/client/${id}/incoming`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getMain, getTransaction"],
    }),

    postEmailConfig: builder.mutation({
      query: ({ id, data }) => ({
        url: `api/mail/config`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Main"],
    }),

    // createStatus: builder.mutation({
    //   query: ({ data }) => ({
    //     url: `api/create/status`,
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["status"],
    // }),

    createStatus: builder.mutation({
      query: ({ data }) => ({
        url: `api/create/status`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getMain"],
    }),

    readNotification: builder.mutation({
      query: ({ notification_id }) => ({
        url: `api/notification/read`,
        method: "POST",
        body: {
          notification_id: notification_id,
        },
      }),
      invalidatesTags: ["getNotification"],
    }),

    resendWelcomeMail: builder.mutation({
      query: ({ id }) => ({
        url: `api/client/${id}/resend-mail`,
        method: "POST",
      }),
    }),

    // PUT

    uploadLogo: builder.mutation({
      query: ({ white_logo }) => ({
        url: `api/company/logo`,
        method: "PUT",
        body: { white_logo },
      }),
      invalidatesTags: ["main"],
    }),
    uploadImage: builder.mutation({
      query: ({ client_id, attachment, type_id, description }) => ({
        url: `api/client/attachment`,
        method: "PUT",
        body: { client_id, attachment, type_id, description },
      }),
      invalidatesTags: ["main"],
    }),

    approveOtp: builder.mutation({
      query: ({ id, confirm_otp }) => ({
        url: `api/client/${id}/confirm-otp`,
        method: "PUT",
        body: { confirm_otp },
      }),
      invalidatesTags: ["getClients"],
    }),

    updateClient: builder.mutation({
      query: ({
        id,
        email,
        first_name,
        last_name,
        password,
        iban,
        country,
        outgoing_fee,
        send_otp,
        allow_transfer,
        send_welcome_email,
      }) => ({
        url: `api/update/client/${id}`,
        method: "PUT",
        body: {
          email,
          first_name,
          last_name,
          password,
          iban,
          country,
          outgoing_fee,
          send_otp,
          allow_transfer,
          send_welcome_email,
        },
      }),
    }),

    updateStatus: builder.mutation({
      query: ({ id, name, color }) => ({
        url: `api/edit/status/${id}`,
        method: "PUT",
        body: { name, color },
      }),
      invalidatesTags: ["getMain"],
    }),

    updateStatusTransaction: builder.mutation({
      query: ({ id, status_id }) => ({
        url: `api/transaction/${id}/edit`,
        method: "PUT",
        body: { status_id },
      }),
      invalidatesTags: ["getTransaction"],
    }),

    updateSchedule: builder.mutation({
      query: ({ id, status_id }) => ({
        url: `api/schedule/${id}/edit`,
        method: "PUT",
        body: { status_id },
      }),
      invalidatesTags: ["getSchedule"],
    }),

    updateEmailConfig: builder.mutation({
      query: ({ data }) => ({
        url: `api/edit/mail/config?mail_config_id=${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Main"],
    }),

    //Delete

    deleteStatus: builder.mutation({
      query: (status_id) => ({
        url: `api/delete/status/${status_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getMain"],
    }),

    deleteTransaction: builder.mutation({
      query: (id) => ({
        url: `api/transaction/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["getTransaction"],
    }),
    
    // upload receipt 

    uploadReceipt: builder.mutation({
      query: ({ id, data }) => ({
        url: `api/transaction/${id}/uploadReceipt`,
        method: "POST",
        body: data, // Directly send the JSON object with the Base64 string
        headers: {
          "Content-Type": "application/json", // Ensure it's sent as JSON
        },
      }),
      invalidatesTags: ["uploadReceiptTransaction"],
    }),
    
  }),
});

export const {
  useGetMainQuery,
  useLazyGetMainQuery,
  useLazyGetClientsListQuery,
  useGetAdminMainQuery,
  useGetAdminNotificationQuery,
  useLazyGetInvoiceListQuery,
  useGetSingleClientQuery,
  useGetTransactionsAdminQuery,
  useLazyGetTransactionsAdminQuery,
  useGetALLClientsListQuery,
  useGetSingleClientTransactionsQuery,
  useLazyGetClientTransactionsDetailsQuery,
  useGetBTCQuery,
  useLazyGetSingleAdminTransactionsQuery,
  useGetScheduleAdminQuery,
  useLazyGetScheduleAdminQuery,

  // POSTS
  useCreateClientMutation,
  useLoginAsClientMutation,
  useCreateTransactionMutation,
  useCreateClientTransactionMutation,
  usePostEmailConfigMutation,
  useCreateBTCMutation,
  useReadNotificationMutation,
  useCreateStatusMutation,
  useResendWelcomeMailMutation,

  // PUT
  useUploadImageMutation,
  useUpdateClientMutation,
  useUploadLogoMutation,
  useUpdateSettleMutation,
  useApproveOtpMutation,
  useUpdateStatusMutation,
  useUpdateStatusTransactionMutation,
  useUpdateScheduleMutation,
  useUpdateEmailConfigMutation,

  //Delete

  useDeleteStatusMutation,
  useDeleteTransactionMutation,

  //Upload Receipt
  useUploadReceiptMutation,
} = mainApi;
