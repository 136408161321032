import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  usePostEmailConfigMutation,
  useUpdateEmailConfigMutation,
  useGetAdminMainQuery,
} from "services/mainApi";
import Statuses from "./Statuses";
import "./settingscmp.scss";

const SettingsCmp = () => {
  const { data: main } = useGetAdminMainQuery();
  const [data, setFormData] = useState("");
  const [addMailConfig] = usePostEmailConfigMutation();
  const [updateMailConfig] = useUpdateEmailConfigMutation();
  const [readOnly, setReadOnly] = useState(true);

  const [isLoading, setIsLoading] = useState();

  const handleChange = (e) => {
    setFormData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const result = await addMailConfig({ data });

    if ("error" in result) {
      setIsLoading(false);
      console.log("errror");
    } else {
      setIsLoading(false);
      toast.success("Succesfully");
    }
  };

  useEffect(() => {
    if (main) {
      setFormData(main?.mail_config);
    }
  }, [main]);

  const toggleEdit = (e) => {
    e.preventDefault();
    setReadOnly(!readOnly);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const result = await updateMailConfig({ data });

    if ("error" in result) {
      console.log("errror");
      setIsLoading(false);
    } else {
      toast.success("Succesfully");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="bg-white w-95 m-auto shadow p-5 mb-5 bg-white rounded">
        <div className="w-100 border-bottom pt-4 ">
          <h4 className="mx-4">
            Email Configuration{" "}
            <i
              style={{ fontSize: "30px" }}
              className="text-primary fa-regular fa-envelope"
            />
          </h4>
        </div>
        <div className=" mt-4">
          {" "}
          <form className="w-75 m-auto ">
            <div>
              <div className="w-100">
                <div className="form-group mb-2 d-flex">
                  <div className="w-20">
                    <label
                      className="fw-bold w-100  mt-2"
                      htmlFor="exampleInputEmail1"
                    >
                      User Name
                    </label>
                  </div>
                  <input
                    type="text"
                    className="form-control w-80"
                    placeholder="User Name"
                    name="username"
                    onChange={handleChange}
                    defaultValue={data?.username}
                    readOnly={main?.mail_config ? readOnly : ""}
                  />
                </div>
                <div className="form-group mb-2 d-flex">
                  <label
                    className="fw-bold w-20 mt-2"
                    htmlFor="exampleInputEmail1"
                  >
                    Password
                  </label>
                  <input
                    type="text"
                    className="form-control w-80"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    defaultValue={data?.password}
                    readOnly={main?.mail_config ? readOnly : ""}
                  />
                </div>
                <div className="form-group mb-2 d-flex">
                  <label
                    className="fw-bold w-20 mt-2"
                    htmlFor="exampleInputEmail1"
                  >
                    From Name
                  </label>
                  <input
                    type="text"
                    className="form-control w-80"
                    placeholder="From Name"
                    name="from_name"
                    onChange={handleChange}
                    defaultValue={data?.from_name}
                    readOnly={main?.mail_config ? readOnly : ""}
                  />
                </div>
                <div className="form-group mb-2 d-flex">
                  <label
                    className="fw-bold w-20 mt-2"
                    htmlFor="exampleInputEmail1"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control w-80"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    defaultValue={data?.email}
                    readOnly={main?.mail_config ? readOnly : ""}
                  />
                </div>
                <div className="form-group mb-2 d-flex">
                  <label
                    className="fw-bold w-20 mt-2"
                    htmlFor="exampleInputEmail1"
                  >
                    Host
                  </label>
                  <input
                    type="text"
                    className="form-control w-80"
                    placeholder="Host"
                    name="host"
                    onChange={handleChange}
                    defaultValue={data?.host}
                    readOnly={main?.mail_config ? readOnly : ""}
                  />
                </div>
                <div className="form-group mb-2 d-flex">
                  <label
                    className="fw-bold w-20 mt-2"
                    htmlFor="exampleInputEmail1"
                  >
                    Smtp Port
                  </label>
                  <input
                    type="text"
                    className="form-control w-80"
                    placeholder="Smtp Port"
                    name="smtp_port"
                    onChange={handleChange}
                    defaultValue={data?.smtp_port}
                    readOnly={main?.mail_config ? readOnly : ""}
                  />
                </div>
                <div className="form-group mb-2 d-flex">
                  <label
                    className="fw-bold w-20 mt-2"
                    htmlFor="exampleInputEmail1"
                  >
                    Imap Port
                  </label>
                  <input
                    type="text"
                    className="form-control w-80"
                    placeholder="Imap Port"
                    name="imap_port"
                    onChange={handleChange}
                    defaultValue={data?.pop3_port}
                    readOnly={main?.mail_config ? readOnly : ""}
                  />
                </div>
                <div className="form-group mb-2 d-flex">
                  <label
                    className="fw-bold w-20 mt-2"
                    htmlFor="exampleInputEmail1"
                  >
                    Pop3 Port
                  </label>
                  <input
                    type="text"
                    className="form-control w-80"
                    placeholder="Pop3"
                    name="pop3_port"
                    onChange={handleChange}
                    defaultValue={data?.pop3_port}
                    readOnly={main?.mail_config ? readOnly : ""}
                  />
                </div>
                <div className="form-group mb-2 d-flex">
                  <label
                    className="fw-bold w-20 mt-2"
                    htmlFor="exampleInputEmail1"
                  >
                    Encryption
                  </label>
                  <input
                    type="text"
                    className="form-control w-80"
                    placeholder="Encryption"
                    name="encryption"
                    onChange={handleChange}
                    defaultValue={data?.encryption}
                    readOnly={main?.mail_config ? readOnly : ""}
                  />
                </div>
              </div>
            </div>
            <div className="w-100 text-center mt-4">
              {" "}
              {main?.mail_config ? (
                !readOnly ? (
                  <button
                    disabled={isLoading ? true : false}
                    onClick={handleUpdate}
                    className="btn btn-primary"
                  >
                    {isLoading ? "Loading..." : "Save"}
                  </button>
                ) : (
                  <button onClick={toggleEdit} className="btn btn-primary">
                    Edit
                  </button>
                )
              ) : (
                <button
                  disabled={isLoading ? true : false}
                  onClick={handleSubmit}
                  className="btn btn-primary"
                >
                  {isLoading ? "Loading..." : "Add"}
                </button>
              )}
            </div>
          </form>
        </div>{" "}
      </div>
      <Statuses />
    </div>
  );
};

export default SettingsCmp;
