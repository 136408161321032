import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Base64 } from "js-base64";
import Modal from "../../shared/Modal/Modal";

import "./client.scss";
import UploadDocument from "./UploadDocument";

import {
  useUpdateClientMutation,
  useGetSingleClientQuery,
  useGetAdminMainQuery,
  useResendWelcomeMailMutation,
} from "services/mainApi";

const UpdateClient = () => {
  const [updateClient] = useUpdateClientMutation();
  const {
    data: main,
    isLoading: loading,
    isError: error,
  } = useGetAdminMainQuery();

  const [resendMail] = useResendWelcomeMailMutation();

  const { id } = useParams();
  const { data: singleClient } = useGetSingleClientQuery(id);
  const [data, setFormData] = useState(singleClient);
  const [loadingMail, setLoading] = useState(false);
  const [decode, setDecode] = useState(true);
  const [isDocument, setIsDocument] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "real_password") {
      setDecode(false);
      setFormData({
        ...data,
        detail: { ...data.detail, [e.target.name]: e.target.value },
      });
    } else if (
      e.target.name === "country" ||
      e.target.name === "iban" ||
      e.target.name === "outgoing_fee"
    ) {
      setFormData({
        ...data,
        detail: { ...data.detail, [e.target.name]: e.target.value },
      });
    } else if (
      e.target.name === "send_otp" ||
      e.target.name === "allow_transfer" ||
      e.target.name === "send_welcome_email"
    ) {
      setFormData({
        ...data,
        detail: { ...data.detail, [e.target.name]: e.target.checked },
      });
    } else {
      setFormData({ ...data, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (singleClient) {
      setFormData(singleClient);
    }
  }, [singleClient]);

  const handleResendEmail = async (event) => {
    setLoading(true);
    event.preventDefault();
    const result = await resendMail({
      id: data.id,
    });

    if ("error" in result) {
      console.log("errror");
      setLoading(false);
    } else {
      toast.success("Sent Succesfully");
      setLoading(false);
      setOpenModal(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await updateClient({
      id: data.id,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      password: data.detail.real_password,
      iban: data.detail.iban,
      country: data.detail.country,
      outgoing_fee: data.detail.outgoing_fee,
      send_otp: data.detail.send_otp,
      allow_transfer: data.detail.allow_transfer,
      send_welcome_email: data.detail.send_welcome_email,
    });

    if ("error" in result) {
      console.log("errror");
    } else {
      toast.success("Updated Succesfully");
    }
  };

  {
    /* <UploadDocument id={id} main={main} /> */
  }

  return (
    <div className=" w-95 m-auto bg-white shadow-sm p-3 mb-5 bg-white rounded">
      <Modal handleCancel={() => setOpenModal(false)} open={openModal}>
        <div className="text-center my-4">
          {" "}
          <h4>Are you sure to resend Welcome Mail? </h4>
          <div className="d-flex gap-2 justify-content-center">
            <button className="btn btn-primary" onClick={handleResendEmail}>
              {loadingMail ? "Loading..." : "Yes"}
            </button>
            <button
              onClick={() => setOpenModal(false)}
              className="btn btn-danger border-0"
            >
              No
            </button>
          </div>
        </div>
      </Modal>
      <div></div>
      <div className="w-100 m-auto mb-4 border-bottom d-flex justify-content-between">
        <div>
          <h4 className="fw-bold my-3">Update Fields</h4>
        </div>
        <div></div>
        <div className="d-flex gap-2 ">
          <div>
            <button
              onClick={() => setOpenModal(true)}
              className="btn btn-primary bg-green border-0"
            >
              Resend Welcome Mail <i className="fa-solid fa-envelope" />
            </button>
          </div>
          <div>
            <button
              onClick={() => setIsDocument(!isDocument)}
              className="btn btn-primary"
            >
              {isDocument ? "Information" : " Upload Documents"}{" "}
              <i className="fa-solid fa-image" />
            </button>
          </div>
        </div>
      </div>
      {isDocument ? (
        <UploadDocument id={id} main={main} />
      ) : (
        <form className="w-75 m-auto ">
          <div>
            <div className="w-100">
              <div className="form-group mb-2">
                <label className="fw-bold">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter Email"
                  onChange={handleChange}
                  value={data?.email || ""}
                />
              </div>
              <div className="form-group mb-2">
                <label className="fw-bold">First Name</label>
                <input
                  name="first_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter First Name"
                  onChange={handleChange}
                  value={data?.first_name || ""}
                />
              </div>

              <div className="form-group mb-2">
                <label className="fw-bold">Last Name</label>
                <input
                  name="last_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter Last Name"
                  onChange={handleChange}
                  value={data?.last_name || ""}
                />
              </div>
              <div className="form-group mb-2">
                <label className="fw-bold">IBAN</label>
                <input
                  name="iban"
                  type="text"
                  className="form-control"
                  placeholder="Enter IBAN"
                  onChange={handleChange}
                  value={data?.detail.iban || ""}
                />
              </div>
              <div className="form-group mb-2">
                <label className="fw-bold">Password</label>
                <input
                  name="real_password"
                  className="form-control"
                  placeholder="Enter Password"
                  onChange={handleChange}
                  value={
                    decode
                      ? Base64.decode(data?.detail?.real_password || "")
                      : data?.detail?.real_password
                  }
                />
              </div>
              <div className="form-group mb-2">
                <label className="fw-bold">Country</label>
                <input
                  type="text"
                  name="country"
                  className="form-control"
                  placeholder="Enter Country"
                  onChange={handleChange}
                  value={data?.detail?.country || ""}
                />
              </div>

              {/* <img
                src={endpoint + data?.documents[0]?.attachment}
                alt="imagee"
              ></img> */}
            </div>
          </div>
          <div className="form-group d-flex  gap-2 my-4">
            <label className="w-5 fw-bold mt-2">Fee</label>
            <input
              type="text"
              name="outgoing_fee"
              className="form-control"
              value={data?.detail?.outgoing_fee}
              style={{ width: "10%" }}
              onChange={handleChange}
            />
            <div className="input-group-append">
              <span className="input-group-text">%</span>
            </div>
            <div className="form-group mb-2"></div>
          </div>
          <div className="form-group mb-2 d-flex gap-2 mt-4">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              id="flexCheckDefault"
              name="send_otp"
              onChange={handleChange}
              checked={data?.detail?.send_otp || ""}
            />{" "}
            <label className="fw-bold">Send OTP Automatically</label>
          </div>

          <div className="form-group mb-2 d-flex gap-2 mt-4">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              id="flexCheckDefault"
              name="allow_transfer"
              onChange={handleChange}
              checked={data?.detail?.allow_transfer || ""}
            />{" "}
            <label className="fw-bold">Allow Transfer</label>
          </div>
          <div className="form-group mb-2 d-flex gap-2 mt-4">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              id="flexCheckDefault"
              checked={data?.detail?.send_welcome_email || ""}
              name="send_welcome_email"
              onChange={handleChange}
            />{" "}
            <label className="fw-bold">Send Welcome Mail</label>
          </div>
          <div className="w-100 text-center mt-4">
            {" "}
            <button onClick={handleSubmit} className="btn btn-primary">
              Update Client
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default UpdateClient;
