import React, { useState, useEffect } from "react";
import PrivateRouteAdmin from "shared/PrivateRouteAdmin/PrivateRouteAdmin.js";
import Sidebar from "shared/SideBar/Sidebar.jsx";
import MobileSide from "shared/MobileSide/MobileSidebar.jsx";
import { useSelector } from "react-redux";
import Header from "shared/Header/Header.jsx";
import HomeAdminComp from "components/admin/Home/HomeAdminComp.jsx";

const AdminHome = () => {
  const [openMobileSide, setOpenMobileSide] = useState(false);
  const [isMobile, setIsMobile] = useState("");
  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  const openSideBar = () => {
    setOpenMobileSide(!openMobileSide);
  };

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);
  return (
    <div className="w-100 d-flex ">
      {isMobile ? (
        openMobileSide ? (
          <MobileSide isAdmin={true} openSideBar={openSideBar} />
        ) : (
          ""
        )
      ) : (
        <Sidebar isAdmin={true} />
      )}
      <div className="w-100 bg-light">
        <Header
          openMobileSide={openMobileSide}
          openSideBar={openSideBar}
          isAdmin={true}
        />
        <HomeAdminComp />
      </div>
    </div>
  );
};

export default PrivateRouteAdmin(AdminHome);
