import React, { useState } from "react";
import "./table.scss";
import Details from "./Details.jsx";
import moment from "moment";
import {
  useLazyGetSingleAdminTransactionsQuery,
  useGetAdminMainQuery,
  useUpdateStatusTransactionMutation,
  useDeleteTransactionMutation,
  useUploadReceiptMutation, // Add the upload receipt mutation
} from "services/mainApi";
import { toast } from "react-toastify";
import formatNumber from "../../../../helpers/NumberFormat.jsx";

const Table = ({ transactions }) => {
  const [openModal, setOpenModal] = useState(false);
  const [getDetails, { data: details }] =
    useLazyGetSingleAdminTransactionsQuery();

  const { data: main } = useGetAdminMainQuery();

  const [updateStatusTransaction] = useUpdateStatusTransactionMutation();
  const [deleteTransaction] = useDeleteTransactionMutation();
  const [uploadReceipt] = useUploadReceiptMutation();

  const [file, setFile] = useState(null);
  const [transactionId, setTransactionId] = useState(null);

  const isLocalHost = !!(
    location.hostname === "localhost" || location.hostname === "127.0.0.1"
  );

  const baseUrl = isLocalHost
    ? "http://bankio.stage/backend"
    : window.location.protocol + "/backend";

  const triggerModal = () => {
    setOpenModal(!openModal);
  };

  const getDetailsFunc = (id) => {
    triggerModal();
    getDetails(id);
  };

  const updateTransactionStatus = async (id, status_id) => {
    const result = await updateStatusTransaction({
      id: id,
      status_id: status_id,
    });

    if ("error" in result) {
      console.log("error");
    } else {
      toast.success("Transaction status updated successfully.");
    }
  };

  const deleteTrans = async (id) => {
    const result = await deleteTransaction(id);
    if ("error" in result) {
      console.log("error");
    } else {
      toast.success("Deleted Successfully");
    }
  };

  const handleUpload = async (transactionId) => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    try {
      const base64String = await toBase64(file);

      const data = {
        file: base64String,
      };

      await uploadReceipt({ id: transactionId, data });
      toast.success("Receipt uploaded successfully");

      setFile(null);
    } catch (err) {
      console.error("Upload failed:", err);
      toast.error("Failed to upload receipt");
    }
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = (e, id) => {
    setFile(e.target.files[0]);
    setTransactionId(id);
  };

  const handleClick = (filePath) => {
    window.open(baseUrl + filePath, "_blank");
  };

  return (
    <div className="table-wrapper">
      <Details
        details={details}
        openModal={openModal}
        triggerModal={triggerModal}
      />
      <div className="p-3"></div>
      <table className="table m-auto ">
        <thead className="">
          <tr className="bg-light">
            <th scope="col " className="text-grey fw-normal text-center">
              ID
            </th>
            <th scope="col" className="text-grey fw-normal text-center">
              Amount
            </th>
            <th scope="col" className="text-grey fw-normal text-center">
              Status
            </th>
            <th scope="col" className="text-grey fw-normal text-center">
              Type
            </th>
            <th scope="col" className="text-grey fw-normal text-center">
              Date
            </th>
            <th scope="col" className="text-grey fw-normal text-center">
              Uploaded Receipt
            </th>
            <th scope="col" className="text-grey fw-normal text-center"></th>
          </tr>
        </thead>
        <tbody>
          {transactions?.length > 0 ? (
            transactions?.map((el, index) => (
              <tr key={index}>
                <th className="text-primary text-center" scope="row">
                  {el.reference_code}
                </th>
                <td className=" fw-bold text-center">
                  {formatNumber(el.amount)} €
                </td>

                <td className=" fw-bold text-center">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn bg-white dropdown-toggle border-0"
                      style={{ color: el?.status?.color }}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {el?.status?.name}
                    </button>
                    <div className="dropdown-menu">
                      {main?.statuses?.map((item, index) => {
                        return (
                          <div className="d-flex justify-content-between  w-95 m-auto">
                            <h6
                              onClick={() =>
                                updateTransactionStatus(el.id, item.id)
                              }
                              className="status-item w-75"
                              type="button"
                            >
                              {item.name}
                            </h6>
                            <div
                              className="color-picker-round mt-0"
                              type="button"
                              style={{
                                backgroundColor: item.color,
                                boxShadow: "0 0 25px" + item.color,
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </td>
                <td className=" fw-bold bg-light text-center text-orange">
                  {el.type === "in" ? "Incoming" : "Outgoing"}
                </td>
                <td className=" fw-bold text-center">
                  {moment(el.created_at).format("DD/MM/YYYY")}
                </td>
                <td className=" fw-bold text-center">
                  {el.has_uploaded_receipt ? (
                    <button
                      className="btn btn-success btn-sm"
                      title="View Uploaded Receipt"
                      onClick={() => handleClick(el.receipt_file_path)} // Trigger the view file action
                    >
                      <i className="fa-solid fa-eye"></i> View Uploaded
                    </button>
                  ) : (
                    "Not Uploaded"
                  )}
                </td>
                <td>
                  <div
                    className="d-flex justify-content-center align-items-center gap-2"
                    style={{ cursor: "pointer" }}
                  >
                    {/* Trash Icon */}
                    <button
                      className="btn btn-danger btn-sm"
                      style={{ marginRight: "15px" }}
                      title="Delete Transaction"
                      onClick={() => deleteTrans(el.id)}
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>

                    {/* Styled File Input */}
                    <div className="custom-file-upload">
                      <label htmlFor={`file-${el.id}`} className="btn btn-outline-primary btn-sm">
                        <i className="fa-solid fa-paperclip"></i> Attach
                      </label>
                      <input
                        type="file"
                        id={`file-${el.id}`}
                        className="d-none"
                        onChange={(e) => handleFileChange(e, el.id)} // Handle file selection
                      />
                    </div>

                    {/* Upload Button */}
                    <button
                      className="btn btn-success btn-sm"
                      title="Upload File"
                      onClick={() => handleUpload(el.id)} // Trigger the upload with the transaction ID
                    >
                      <i className="fa-solid fa-upload"></i> Upload
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <div></div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
